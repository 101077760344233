@keyframes move {
    100% {
        transform: translate3d(0, 0, 1px) rotate(360deg);
    }
}

.background {
    position: relative;
    width: 100%;
    padding-bottom: 5rem;
    
    top: 0;
    left: 0;
    background: #37517e;
    overflow: hidden;
}

.background span {
    width: 5vmin;
    height: 5vmin;
    border-radius: 5vmin;
    backface-visibility: hidden;
    position: absolute;
    animation: move;
    animation-duration: 45;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
}


.background span:nth-child(0) {
    color: #583C87;
    top: 15%;
    left: 10%;
    animation-duration: 27s;
    animation-delay: -26s;
    transform-origin: 23vw -18vh;
    box-shadow: 10vmin 0 1.3462303628782168vmin currentColor;
}
.background span:nth-child(1) {
    color: #026ac3;
    top: 53%;
    left: 77%;
    animation-duration: 8s;
    animation-delay: -42s;
    transform-origin: 6vw 20vh;
    box-shadow: -10vmin 0 1.6827350936956724vmin currentColor;
}
.background span:nth-child(2) {
    color: #583C87;
    top: 86%;
    left: 30%;
    animation-duration: 23s;
    animation-delay: -11s;
    transform-origin: -8vw -1vh;
    box-shadow: -10vmin 0 1.6860633257227537vmin currentColor;
}
.background span:nth-child(3) {
    color: #026ac3;
    top: 27%;
    left: 27%;
    animation-duration: 54s;
    animation-delay: -48s;
    transform-origin: -7vw -2vh;
    box-shadow: -10vmin 0 1.514904547190565vmin currentColor;
}
.background span:nth-child(4) {
    color: #d4e7f6;
    top: 92%;
    left: 82%;
    animation-duration: 43s;
    animation-delay: -37s;
    transform-origin: 6vw -5vh;
    box-shadow: 10vmin 0 1.3612598039634383vmin currentColor;
}
.background span:nth-child(5) {
    color: #d4e7f6;
    top: 24%;
    left: 82%;
    animation-duration: 6s;
    animation-delay: -15s;
    transform-origin: 16vw -21vh;
    box-shadow: -10vmin 0 1.8771991128769254vmin currentColor;
}
.background span:nth-child(6) {
    color: #d4e7f6;
    top: 58%;
    left: 63%;
    animation-duration: 18s;
    animation-delay: -36s;
    transform-origin: -23vw -11vh;
    box-shadow: -10vmin 0 1.7537913036679547vmin currentColor;
}
.background span:nth-child(7) {
    color: #d4e7f6;
    top: 14%;
    left: 95%;
    animation-duration: 18s;
    animation-delay: -46s;
    transform-origin: -16vw 1vh;
    box-shadow: 10vmin 0 2.2451579971256406vmin currentColor;
}
.background span:nth-child(8) {
    color: #583C87;
    top: 32%;
    left: 58%;
    animation-duration: 21s;
    animation-delay: -49s;
    transform-origin: 23vw -17vh;
    box-shadow: 10vmin 0 1.4977421654715366vmin currentColor;
}
.background span:nth-child(9) {
    color: #026ac3;
    top: 62%;
    left: 9%;
    animation-duration: 40s;
    animation-delay: -31s;
    transform-origin: 3vw -18vh;
    box-shadow: -10vmin 0 1.2736416648015534vmin currentColor;
}
.background span:nth-child(10) {
    color: #026ac3;
    top: 50%;
    left: 2%;
    animation-duration: 30s;
    animation-delay: -41s;
    transform-origin: 18vw 21vh;
    box-shadow: -10vmin 0 1.8247104499830797vmin currentColor;
}
.background span:nth-child(11) {
    color: #583C87;
    top: 7%;
    left: 62%;
    animation-duration: 27s;
    animation-delay: -36s;
    transform-origin: -23vw 6vh;
    box-shadow: -10vmin 0 2.0808897336740215vmin currentColor;
}
.background span:nth-child(12) {
    color: #d4e7f6;
    top: 15%;
    left: 67%;
    animation-duration: 12s;
    animation-delay: -46s;
    transform-origin: 9vw -15vh;
    box-shadow: 10vmin 0 1.867131469611041vmin currentColor;
}
.background span:nth-child(13) {
    color: #026ac3;
    top: 59%;
    left: 17%;
    animation-duration: 17s;
    animation-delay: -19s;
    transform-origin: 19vw -1vh;
    box-shadow: -10vmin 0 1.6171119171789599vmin currentColor;
}
.background span:nth-child(14) {
    color: #d4e7f6;
    top: 22%;
    left: 52%;
    animation-duration: 23s;
    animation-delay: -2s;
    transform-origin: -17vw -9vh;
    box-shadow: 10vmin 0 2.0712239390209852vmin currentColor;
}
.background span:nth-child(15) {
    color: #026ac3;
    top: 25%;
    left: 49%;
    animation-duration: 15s;
    animation-delay: -43s;
    transform-origin: 14vw 20vh;
    box-shadow: -10vmin 0 1.4960568093865272vmin currentColor;
}
.background span:nth-child(16) {
    color: #026ac3;
    top: 26%;
    left: 47%;
    animation-duration: 14s;
    animation-delay: -12s;
    transform-origin: 21vw -1vh;
    box-shadow: 10vmin 0 1.5259977401147518vmin currentColor;
}
.background span:nth-child(17) {
    color: #583C87;
    top: 49%;
    left: 74%;
    animation-duration: 18s;
    animation-delay: -29s;
    transform-origin: -17vw -13vh;
    box-shadow: 10vmin 0 2.0600192335394194vmin currentColor;
}
.background span:nth-child(18) {
    color: #026ac3;
    top: 62%;
    left: 32%;
    animation-duration: 36s;
    animation-delay: -15s;
    transform-origin: -15vw 2vh;
    box-shadow: -10vmin 0 1.450855197472142vmin currentColor;
}
.background span:nth-child(19) {
    color: #026ac3;
    top: 28%;
    left: 40%;
    animation-duration: 24s;
    animation-delay: -27s;
    transform-origin: 18vw 15vh;
    box-shadow: -10vmin 0 1.4746644995193903vmin currentColor;
}
.background span:nth-child(20) {
    color: #026ac3;
    top: 71%;
    left: 89%;
    animation-duration: 34s;
    animation-delay: -39s;
    transform-origin: -10vw 25vh;
    box-shadow: 10vmin 0 1.5177152116916017vmin currentColor;
}
.background span:nth-child(21) {
    color: #026ac3;
    top: 66%;
    left: 11%;
    animation-duration: 27s;
    animation-delay: -43s;
    transform-origin: 20vw 11vh;
    box-shadow: -10vmin 0 1.7030825784664934vmin currentColor;
}
.background span:nth-child(22) {
    color: #583C87;
    top: 18%;
    left: 7%;
    animation-duration: 11s;
    animation-delay: -13s;
    transform-origin: -11vw 8vh;
    box-shadow: 10vmin 0 1.4634582808269727vmin currentColor;
}
.background span:nth-child(23) {
    color: #d4e7f6;
    top: 52%;
    left: 64%;
    animation-duration: 46s;
    animation-delay: -41s;
    transform-origin: 20vw -2vh;
    box-shadow: -10vmin 0 1.494865377313982vmin currentColor;
}
.background span:nth-child(24) {
    color: #026ac3;
    top: 52%;
    left: 58%;
    animation-duration: 7s;
    animation-delay: -18s;
    transform-origin: 21vw 18vh;
    box-shadow: 10vmin 0 1.448794967701274vmin currentColor;
}
.background span:nth-child(25) {
    color: #026ac3;
    top: 56%;
    left: 13%;
    animation-duration: 37s;
    animation-delay: -32s;
    transform-origin: -23vw -20vh;
    box-shadow: 10vmin 0 1.8211967416577306vmin currentColor;
}
.background span:nth-child(26) {
    color: #026ac3;
    top: 51%;
    left: 85%;
    animation-duration: 7s;
    animation-delay: -2s;
    transform-origin: -2vw -16vh;
    box-shadow: -10vmin 0 1.578496938449771vmin currentColor;
}

.contact{
    z-index: 1;
    grid-template-columns: repeat(2, 1fr);
    padding: 2rem 0;
}
.address{
    width: 100%;
    flex-direction: column;
}
.address_span{
    font-weight: bold;
    font-size: 1rem;
}
.leaflet-container{
    width: 500px;
    height: 300px;
    border-radius: 10px;
}
.address_text{
    color: var(--white_text);
    opacity: .8;
}

.contact_form{
    padding: 3rem 1rem;
    background-color: rgba(255, 255, 255, 0.123);
    backdrop-filter: blur(5px);
    border-radius: 10px;
    width: 100%;
}
.contact_form>div>label{
    position: absolute;
    left: -99999900px;
}
.contact_form>div>input,.contact_form>div>textarea{
    width: 100%;
    padding: .2rem 1rem;
    border-radius: 10px;
    outline: none;
    border: none;
    margin: .25rem 0;
}
.contact_form>div>textarea{
    height: 10rem;
}
.submit_div>button{
    padding: .5rem 2rem;
    border-radius: 20px;
    border: none;
    background-color: var(--light_blue);
    color: var(--white_text);
    font-weight: bold;
    transition: .3s ease-in-out;
}
.submit_div>button:hover{
    background-color: var(--nav_back_blur);
}
.contact_form>div>em{
    color: var(--white_text);
    font-size: .8rem;
}
.contact_form>div>h3{
    margin-bottom: 1rem;
    color: var(--white_text);
}
