@media screen and (max-width: 950px) {
    .leaflet-container{
        width: 300px !important;
        margin-bottom: 1rem;
    }

}
@media screen and (max-width: 800px) {
    .heading_1{
        font-size: 2rem !important;
    }
    .menu{
        display: flex;
        z-index: 8;
    }
    .unordered_list{
        position: absolute;
        flex-direction: column;
        width: 100%;
        height: 100vh;
        top:0;
        left: -100%;
        background-color: var(--nav_back);
        padding: 5em 0;
    }
    .unordered_list.active{
        left: 0;
    }
    .list_item{
        border: 2px solid var(--light_blue);
        border-radius: 5px;
        margin: 10px 0;
        width: 8rem;
        opacity: 0;
        transform: translateX(-150px);
    }
    
    .unordered_list.active > .list_item:nth-child(1){
        left: 0;
        animation: slidein .3s ease .3s forwards ;
    }
    .unordered_list.active > .list_item:nth-child(2){
        left: 0;
        animation: slidein .3s ease .4s forwards ;
    }
    .unordered_list.active > .list_item:nth-child(3){
        left: 0;
        animation: slidein .3s ease .5s forwards ;
    }
    .unordered_list.active > .list_item:nth-child(4){
        left: 0;
        animation: slidein .3s ease .6s forwards ;
    }

    @keyframes slidein {
        100%{
            opacity: 1;
            transform: translateX(0px);
        }
    }

    .padding_1_side{
        padding: 0 3em;
    }
    .hero_section{
        grid-template-columns: 1fr !important;
    }
    .hero_text{
        order: 2;
        text-align: center;
    }
    .hero_image{
        order: 1;
        padding-top: 5rem;
    }
    
    .hero_image img{
        width: 100%;
        animation: none !important;
    }
    
    @keyframes oscillate {
        0%{
            transform: translateY(0);
        }
        100%{
            transform: translateY(0);
        }

    }

    .about_section{
        grid-template-columns: 1fr !important;
    }
    .small_text p{
        text-align: justify;
    }

    .slide img{
        height: 1.7rem !important;
        padding: 0 1rem !important;
    }
    .service_section{
        grid-template-columns: 1fr!important;
    }
    .service_card img{
        width: 60px !important;
    }
    .contact{
        grid-template-columns: 1fr!important;
    }
    .leaflet-container{
        width: 600px !important;
    }
    
}
@media screen and (max-width: 700px) {
    .leaflet-container{
        width: 500px !important;
        height: 400px !important;
        margin-bottom: 1rem;
    }

}
@media screen and (max-width: 600px) {
    .padding_1_side{
        padding: 0 1em;
    }
    .leaflet-container{
        width: 400px !important;
    }

}
@media screen and (max-width: 500px) {
    .leaflet-container{
        width: 320px !important;
        margin-bottom: 1rem;
    }

}
@media screen and (max-width: 400px) {
    .leaflet-container{
        width:300px !important;
    }

}
@media screen and (max-width: 350px) {
    .leaflet-container{
        width:280px !important;
    }

}
@media screen and (max-width: 290px) {
    .leaflet-container{
        width:230px !important;
        height: 200px !important;
    }

}

