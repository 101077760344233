@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;700&family=Tilt+Warp&display=swap');


:root {
  --nav_back: #37517e;
  --white_text: #ffffff;
  --light_blue: #00a6eb;
  --light_blue_hover: #048cc7;
  --nav_back_blur: #37517ed5;
  --call_to_duty: #101825e7;
}

*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
  scroll-behavior: smooth;
}
.d_grid{
  display: grid;
  place-items: center;
}
.d_flex{
  display: flex;
  align-items: center;
}
.j_between{
  justify-content: space-between;
}
.j_around{
  justify-content: space-around;
}
.j_center{
  justify-content: center;
}
.padding_1_side{
  padding: 0 10em;
}
.padding_1_top{
  padding-top: 1em;
}
.padding_1_buttom{
  padding-bottom: 1em;
}
.anchor_{
  text-decoration: none;
  color: var(--white_text);
}
.container{
  width: 100%;
  flex-direction: column;
  padding-top: 5em;
}
.container_fluid{
  width: 80%;
  flex-direction: column;
}

.container_fluid h2{
  color: var(--nav_back);
  font-size: 1.5rem;
}

.under_line{
  width: 3rem;
  margin-top: .2rem;
  height: 2px;
  background-color: var(--light_blue);
}
.small_text p{
  font-size: .9rem;
  padding: 5px 0;
}

footer{
  position: fixed;
  height: 50px;
  background-color: var(--nav_back_blur);
  color: var(--white_text);
  bottom: 0;
  width: 100%;
  text-align: center;
  z-index: 8;
}
