

.Nav_hero {
    position: fixed;
    width: 100%;
    top: 0;
    height: 80px;
    z-index: 9;
    background-color: var(--nav_back_blur);
    backdrop-filter: blur(10px);
    box-shadow: 0px 0px 5px 0px black;
}

.Navigation_header {
    width: 100%;
    color: var(--white_text);
    height: 80px;

}

.brand_name {
    font-size: 2.3rem;
    z-index: 8;
}

.unordered_list {
    list-style: none;
    transition: .5s ease;
}

.list_item {
    cursor: pointer;
    font-size: 15px;
    text-align: center;
    padding: .3rem 0;
    margin: 0 .5rem;
}

.list_item a {
    display: block;
    padding: 1em;
    transition: .3s ease;
}

.list_item a:hover {
    color: var(--light_blue);
}

.menu {
    height: 2.5em;
    width: 4em;
    border: 2px solid var(--light_blue);
    border-radius: 5px;
    cursor: pointer;
    display: none;

}

.bar {
    width: 1.8em;
    height: 2px;
    background-color: var(--white_text);
    border-radius: 50%;
    position: relative;
    transition: .5s ease;
}

.bar::before {
    transform: translateY(-10px);
}

.bar::after {
    transform: translateY(10px);
}

.bar::after,
.bar::before {
    content: "";
    position: absolute;
    background-color: var(--white_text);
    width: inherit;
    height: inherit;
    transition: .5s ease;
}

.menu_clicked.bar {
    background-color: transparent;
    transform: rotate(180deg);

}

.menu_clicked.bar::before {
    background-color: var(--light_blue);
    transform: rotate(135deg);

}

.menu_clicked.bar::after {
    background-color: var(--light_blue);
    transform: rotate(45deg);
}

.menu.glow {
    box-shadow: 0px 0px 5px 0px var(--light_blue);
}