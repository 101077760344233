.about_section{
    grid-template-columns: repeat(2, 1fr);
}

.about_image{
    width: 80%;
}
.about_image img{
    width: 100%;
}
.partners{
    width: 100%;
    padding-top: 2rem;
    flex-direction: column;
}
.slide{
    height: 100px;
    
}
.slide img{
    height: 3rem;
    padding: 0 3rem;
}
.about{
    padding-bottom: 2rem;
}