
.frontpage{
    background-color: var(--nav_back);
}
.hero_section{
    grid-template-columns: repeat(2, 1fr);
    width: 80%;
    min-height: 80vh;
    color: var(--white_text);
    background-color: var(--nav_back);
}
.heading_1{
    font-size: 3em;
}
.hero_text p{
    opacity: .9;
}

.hero_text button{
    margin: 2rem 0;
    padding: .8rem 2.5rem;
    border-radius: 50px;
    border: none;
    font-size: .9rem;
    cursor: pointer;
    transition: .3s ease-in-out;
    color: var(--white_text);
    background-color: var(--light_blue);
}

.hero_text button:hover{
    background-color: var(--light_blue_hover);
}
.hero_image img{
    width: 100%;
    animation: oscillate 2s ease-in-out infinite alternate;
}

@keyframes oscillate {
    0%{
        transform: translateY(15px);
    }
    100%{
        transform: translateY(-5px);
    }

}

